<script lang="ts">
export let tag: string
</script>

<span class="tag">{tag}</span>

<style lang="scss">
span {
  width: fit-content;
  height: fit-content;
  border-radius: 25px;
  background: linear-gradient(
    96.98deg,
    #ff560e -34.51%,
    #ff001f 22.52%,
    #8802c8 94.43%,
    #6202fe 144.6%
  );
  background-size: 150% auto;
  /* transition: all .2s ease-in-out; */
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.48px;
  text-align: center;
  padding: 7px 13px;
  color: var(--white);

  @media only screen and (min-width: 1024px) {
    font-size: 16px;
    line-height: 18px;
  }
}
</style>
